import { createRouter, createWebHistory } from 'vue-router'

import loginRouter from '../modules/login/router'
import dashboardLogin from '../modules/dashboard/router'

const routes = [
  {
    path: '/',
    ...loginRouter
  },
  {
    path:'/dashboard',
    ...dashboardLogin
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
