export default {

    name: 'dashboard',
    component:() => import('../layouts/DashboardLayout.vue'),
    children: [
        {
            path:'',
            name: 'welcome-screen',
            component: () => import('../views/Bienvenida.vue')
        },
        // Diputaciones Proceso Electoral 
        {
            path: '/diputaciones/proceso-electoral/universo-aspirantes',
            name: 'diputaciones-universo-aspirantes',
            component: () => import('../views/diputaciones/proceso-electoral/UniversoAspirantes.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/lista-nominal',
            name: 'diputaciones-lista-nominal',
            component: () => import('../views/diputaciones/proceso-electoral/ListaNominal.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/postulaciones-internas',
            name: 'diputaciones-postulaciones-internas',
            component: () => import('../views/diputaciones/proceso-electoral/PostulacionesInternas.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/candidaturas',
            name: 'diputaciones-candidaturas',
            component: () => import('../views/diputaciones/proceso-electoral/Candidaturas.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/bloques-competitividad',
            name: 'diputaciones-bloques-competitividad',
            component: () => import('../views/diputaciones/proceso-electoral/BloquesCompetitividad.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/diputaciones-electas',
            name: 'diputaciones-diputaciones-electas',
            component: () => import('../views/diputaciones/proceso-electoral/DiputacionesElectas.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/titulares-suplentes',
            name: 'diputaciones-titulares-suplentes',
            component: () => import('../views/diputaciones/proceso-electoral/TitularesSuplentes.vue')
        },
        {
            path: '/diputaciones/proceso-electoral/distritos-mayor-poblacion',
            name: 'diputaciones-distritos-mayor-poblacion',
            component: () => import('../views/diputaciones/proceso-electoral/DistritosMayorPoblacion.vue')
        },



        // Diputaciones Ejericicio del cargo
        {
            path: '/diputaciones/ejercicio-cargo/grupo-pelamentario',
            name: 'diputaciones-grupo-parlamentario',
            component: () => import('../views/diputaciones/ejercicio-cargo/GrupoParlamentario.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/nivel-educativo',
            name: 'diputaciones-nivel-educativo',
            component: () => import('../views/diputaciones/ejercicio-cargo/NivelEducativo.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/junta-coordinacion-politica',
            name: 'diputaciones-junta-coordinacion-politica',
            component: () => import('../views/diputaciones/ejercicio-cargo/JuntaCoordinacion.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/mesa-directiva',
            name: 'diputaciones-mesa-directiva',
            component: () => import('../views/diputaciones/ejercicio-cargo/MesaDirectiva.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/comisiones',
            name: 'diputaciones-comisiones',
            component: () => import('../views/diputaciones/ejercicio-cargo/Comisiones.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/asistencias',
            name: 'diputaciones-asistencias',
            component: () => import('../views/diputaciones/ejercicio-cargo/Asistencias.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/particpacion-tribuna',
            name: 'diputaciones-participacion-tribuna',
            component: () => import('../views/diputaciones/ejercicio-cargo/ParticipacionTribuna.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/propuesta-orden-dia',
            name: 'diputaciones-propuesta-orden-dia',
            component: () => import('../views/diputaciones/ejercicio-cargo/PropuestasOrdenDia.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/exhortos',
            name: 'diputaciones-exhortos',
            component: () => import('../views/diputaciones/ejercicio-cargo/Exhortos.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/comparecencias',
            name: 'diputaciones-comparecencias',
            component: () => import('../views/diputaciones/ejercicio-cargo/Comparecencias.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/comparecencias',
            name: 'diputaciones-comparecencias',
            component: () => import('../views/diputaciones/ejercicio-cargo/Comparecencias.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/iniciativa-ley',
            name: 'diputaciones-iniciativa-ley',
            component: () => import('../views/diputaciones/ejercicio-cargo/IniciativasLey.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/redes-sociales',
            name: 'diputaciones-redes-sociales',
            component: () => import('../views/diputaciones/ejercicio-cargo/RedesSociales.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/nombramientos-realizados',
            name: 'diputaciones-nombramientos-realizados',
            component: () => import('../views/diputaciones/ejercicio-cargo/NombramientosRealizados.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/permisos-licencias',
            name: 'diputaciones-permisos-licencias',
            component: () => import('../views/diputaciones/ejercicio-cargo/PermisosLicencias.vue')
        },
        {
            path: '/diputaciones/ejercicio-cargo/violencia-razon-genero',
            name: 'diputaciones-violencia-razon-genero',
            component: () => import('../views/diputaciones/ejercicio-cargo/ViolenciaRazonGenero.vue')
        },
        


        // Municipios Proceso Electoral
        {
            path: '/municipios/proceso-electoral/aspirantes',
            name: 'municipios-procesoElectoral-aspirantes',
            component: () => import('../views/municipios/proceso-electoral/Aspirantes.vue')
        },
        {
            path: '/municipios/proceso-electoral/lista-nominal',
            name: 'municipios-procesoElectoral-lista-nominal',
            component: () => import('../views/municipios/proceso-electoral/ListaNominal.vue')
        },
        {
            path: '/municipios/proceso-electoral/postulaciones-internas',
            name: 'municipios-procesoElectoral-postulaciones-internas',
            component: () => import('../views/municipios/proceso-electoral/PostulacionesInternas.vue')
        },
        {
            path: '/municipios/proceso-electoral/candidaturas',
            name: 'municipios-procesoElectoral-candidaturas',
            component: () => import('../views/municipios/proceso-electoral/Candidaturas.vue')
        },
        {
            path: '/municipios/proceso-electoral/bloques-competitividad',
            name: 'municipios-procesoElectoral-bloques-competitividad',
            component: () => import('../views/municipios/proceso-electoral/BloquesCompetitividad.vue')
        },
        {
            path: '/municipios/proceso-electoral/resultados-elecciones',
            name: 'municipios-procesoElectoral-resultados-elecciones',
            component: () => import('../views/municipios/proceso-electoral/ResultadoElecciones.vue')
        },
        {
            path: '/municipios/proceso-electoral/municipio-mayor-poblacion',
            name: 'municipios-procesoElectoral-municipio-mayor-poblacion',
            component: () => import('../views/municipios/proceso-electoral/MunicipioMayorPoblacion.vue')
        },

        // Municipios Ejercicio del cargo 
        {
            path: '/municipios/ejericio-cargo/protesta',
            name: 'municipios-ejercicio-cargo-protesta',
            component: () => import('../views/municipios/ejercicio-cargo/Protesta.vue')
        },
        {
            path: '/municipios/ejericio-cargo/integracion-comisiones',
            name: 'municipios-ejercicio-cargo-integracion-comisiones',
            component: () => import('../views/municipios/ejercicio-cargo/Comisiones.vue')
        },
        {
            path: '/municipios/ejericio-cargo/sesiones-cabildo',
            name: 'municipios-ejercicio-cargo-sesiones-cabildo',
            component: () => import('../views/municipios/ejercicio-cargo/SesionesCabildo.vue')
        },
        {
            path: '/municipios/ejericio-cargo/redes-sociales',
            name: 'municipios-ejercicio-cargo-redes-sociales',
            component: () => import('../views/municipios/ejercicio-cargo/RedesSociales.vue')
        },
        {
            path: '/municipios/ejericio-cargo/auditorias',
            name: 'municipios-ejercicio-cargo-auditorias',
            component: () => import('../views/municipios/ejercicio-cargo/Auditorias.vue')
        },
        {
            path: '/municipios/ejericio-cargo/informe-anual',
            name: 'municipios-ejercicio-cargo-informe-anual',
            component: () => import('../views/municipios/ejercicio-cargo/InformeAnual.vue')
        },
        {
            path: '/municipios/ejericio-cargo/renucias',
            name: 'municipios-ejercicio-cargo-renuncias',
            component: () => import('../views/municipios/ejercicio-cargo/Renucias.vue')
        },
        {
            path: '/municipios/ejericio-cargo/postulaciones-otro-cargo',
            name: 'municipios-ejercicio-cargo-postulaciones-otros-cargos',
            component: () => import('../views/municipios/ejercicio-cargo/Postulaciones.vue')
        },
        {
            path: '/municipios/ejericio-cargo/permisos-licencias',
            name: 'municipios-ejercicio-cargo-permisos-licencias',
            component: () => import('../views/municipios/ejercicio-cargo/PermisosLicencias.vue')
        },
        {
            path: '/municipios/ejericio-cargo/nombramiento-cabildo',
            name: 'municipios-ejercicio-cargo-nombramientos-cabildo',
            component: () => import('../views/municipios/ejercicio-cargo/NombramientoCabildo.vue')
        },
        {
            path: '/municipios/ejericio-cargo/violencia-politica-razon-genero',
            name: 'municipios-ejercicio-cargo-violencia-politica-razon-genero',
            component: () => import('../views/municipios/ejercicio-cargo/ViolenciaRazonGenero.vue')
        },

        
    ]

}