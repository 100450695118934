export default {
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '../layouts/Login.vue'),
    children: [
        {
            path:'',
            name: 'user-login',
            component: () => import('../views/UserLogin.vue')
        }
    ]
}