import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './styles/style.scss'

import axios from 'axios'
import VueAxios from 'vue-axios'

createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios,axios)
    .mount('#app')



    axios.defaults.baseURL = 'https://site--api-paimef--tvzdhp2q7p8g.code.run/api'
