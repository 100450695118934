<template>
  <NavMenu/>
  <router-view/>
</template>

<script>

import { defineAsyncComponent } from 'vue'

export default {
    components: {
        NavMenu: defineAsyncComponent( () => import('./components/Nav.vue'))
      }
}
</script>

<style>

</style>
